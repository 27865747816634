// ** Auth Endpoints
const url = '/'
export default {
  loginEndpoint: `${url}Login`,
  registerEndpoint: `${url}register`,
  refreshEndpoint: `${url}refresh-token`,
  logoutEndpoint: `${url}logout`,
  getProductsList: `${url}api/Order/List`,
  sendSms: `${url}api/User/SendEmail`,
  sendEmail: `${url}api/User/SendSms`,
 

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'

}
